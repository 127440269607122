import React from "react";
import tabela from "../storage/Tabela 2025/Preview-Tabela.png";
import { Link } from "react-router-dom";

export const CustasEmolumentos = () => {
  // const storage =
  //   "/storage/Tabela Técnica de Reajuste de Emolumentos e Taxas dos Selos Eletrônicos 2023 (Atualizada até 16022023).pdf";
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>Tabela de Custas e Emolumentos</h3>
        <span className=""></span>
      </div>
      <div className="container quem-somos d-flex tabela-emolumentos">
        <Link
          to={`https://see.tjgo.jus.br/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCSTdQVndJPSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--7b2459a1ccd7451364b4a97a50a5490e0404d695/Tabela%20T%C3%A9cnica%20Emolumentos%20e%20Taxas%20dos%20Selos%20Eletr%C3%B4nicos%202025%20(Atualizada%20at%C3%A9%2027122024).xlsx`}
          target="_blank"
        >
          <p className="btn btn-success d-flex align-items-center">
            <i class="bi bi-download fs-5"></i>
            <span>Clique aqui para baixar</span>
          </p>
        </Link>
        <img src={tabela} alt="Tabela de emolumentos imagem" />
      </div>
    </div>
  );
};
